import React from "react";
import { Header, Card } from "semantic-ui-react";
import "../../scss/components/_cards.scss";

export function PlanCard(props) {
    const { plan, selectedPlan, onSelection } = props;
    const { unit_amount, metadata } = plan;
    const { tradename, amountSaved } = metadata;

    const priceInfo = unit_amount / 100;
    const packagePrice = `$${priceInfo.toFixed(2)}`;
    let cardClasses = "plan-card";
    let cardStyle = {}; 
    if (selectedPlan) {
        cardClasses = "plan-card selected-plan";
    }

    cardStyle = {
        width: "10em", 
        height: "90px",      
    };

    return (
        <Card className={cardClasses} style={cardStyle}>
            <div className="card-overlay" onClick={() => onSelection(plan)}></div>
            <Card.Content>
                <div className="card-content">
                    <div className="card-data-section">
                        <Header as="h4">                           
                            {amountSaved > 0 &&  <Card.Meta className="text-center">Save ${amountSaved}</Card.Meta>}
                            {tradename}
                        </Header>
                        <Card.Meta className="text-center"> {packagePrice}</Card.Meta>
                    </div>
                </div>
            </Card.Content>
        </Card>
    );
}
