import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router";
import { Container } from "semantic-ui-react";
import { Loader } from "../../components";
import StepFourComponent from "../../components/screenComponents/subscribe/StepFourComponent";
import { getSpecialOccasionBoxes, upgradeTypeCompose } from "../../services/UpgradeBoxService";
import { removeLocalStorageItem } from "../../services/LocalStorageService";
import { saveStepFour, saveStepFive, saveDiscountCoupon } from "../../store/subscriptions/actions";
import * as CONSTANTS from "../../constants/Global";
import * as ROUTES from "../../navigation/Routes";
import * as LOCALSTORAGE from "../../constants/LocalStorage";
import "../../scss/pages/get_a_box.scss";
import "../../scss/components/_utilities.scss";

function StepFour(props) {
    const { history, subscriptions, plans, upgrades, location, saveStepFour, saveStepFive, saveDiscountCoupon } = props;
    const isJoiningUser = location.state && location.state.isJoiningUser;
    const availableWithPlan = location.state && location.state.availableWithPlan;

    const { relationship, grandmaData, grandpaData, subscriptionPlan, giftType, upgradeType } = subscriptions;
    const [subscription, setSubscription] = useState([]);
    const [subscriptionPlansOptions, setSubscriptionPlansOptions] = useState([]);
    const [showPlans, setShowPlans] = useState(false);
    const [availableMonth, setAvailableMonth] = useState();
    const [otGiftType, setOtGiftType] = useState(giftType);

    useEffect(() => {
        removeLocalStorageItem(LOCALSTORAGE.PLAN_TYPE);

        const getPlansToOneTimeGiftSubscriber = () => {
            const specialOcationBoxes = getSpecialOccasionBoxes(
                relationship,
                giftType ? 1 : 12,
                grandmaData.birthday,
                grandpaData.birthday,
                grandmaData.anniversary
            );

            const upgradeComposed = upgradeTypeCompose(giftType ? giftType : upgradeType, grandmaData);

            if (giftType) setOtGiftType(upgradeComposed);

            const suggestionRequired = specialOcationBoxes.find((box) => box.tradename === upgradeComposed);

            if (availableWithPlan) {
                setAvailableMonth(availableWithPlan);
            } else {
                let availableMonth = suggestionRequired.availableDate.monthDifference;

                if (suggestionRequired.status === "available") {
                    availableMonth = 3;
                }

                if (availableMonth <= 0) {
                    availableMonth = availableMonth + 11;
                }

                setAvailableMonth(availableMonth);
            }
        };

        if (isJoiningUser || upgradeType || availableWithPlan) {
            getPlansToOneTimeGiftSubscriber();
        }
    }, [
        availableWithPlan,
        grandmaData,
        grandpaData,
        relationship,
        subscriptionPlan,
        giftType,
        isJoiningUser,
        otGiftType,
        upgradeType,
    ]);

    const recreateOptions = useCallback(
        (data) => {
            const plansOptionsWithImage = [];

            plans.forEach((plan) => {
                let imageName = plan.metadata.tradename.toLowerCase();
                imageName = imageName.split(" ").join("-");

                if (plan.metadata.priceType === data.type && plan.type === "recurring") {
                    const orderedPlans = {
                        ...plan,
                        image: require(`../../images/boxes/${imageName}-${data.gender}`),
                        availableWithPlan: availableMonth,
                    };

                    plansOptionsWithImage.push(orderedPlans);
                }
            });

            const filteredPlansOptions = plansOptionsWithImage
                .sort((a, b) => {
                    const itemA = Number(a.metadata.packageQty);
                    const itemB = Number(b.metadata.packageQty);
                    const comparison = itemA - itemB;

                    return comparison * -1;
                })
                .filter((plan) => plan.metadata.grandboxType !== CONSTANTS.BOX_TYPES.GIFT);

            const monthlyPlan = filteredPlansOptions.find(
                (plan) => plan.recurring.interval_count === 1 && plan.recurring.interval === "month"
            );

            const monthlyAmount = monthlyPlan ? monthlyPlan.unit_amount / 100 : 0;

            const filteredPlansOptionsWithAmountSaved = filteredPlansOptions.map((plan) => {
                let amountSaved = 0;

                const updatedPlan = { ...plan };

                if (plan.recurring.interval_count === 12 && plan.recurring.interval === "month") {
                    amountSaved = monthlyAmount * 12 - plan.unit_amount / 100;
                } else if (plan.recurring.interval_count === 6 && plan.recurring.interval === "month") {
                    amountSaved = monthlyAmount * 6 - plan.unit_amount / 100;
                }

                updatedPlan.metadata = {
                    ...updatedPlan.metadata,
                    amountSaved,
                };

                return updatedPlan;
            });
            if (filteredPlansOptionsWithAmountSaved.length > 0) {
                // If in the future it is required to filter the plans for joining users or in case of upgrade,
                // uncomments the code block below:
                /*
                    if (isJoiningUser || upgradeType) {
                        const filteredPlansOptionsWithImage = filteredPlansOptions.filter(
                            (plan) =>
                                plan.metadata.packageQty >= availableMonth &&
                                plan.metadata.packageQty !== "1"
                        );
                        setSubscriptionPlansOptions(filteredPlansOptionsWithImage);
                    } else {
                        setSubscriptionPlansOptions(filteredPlansOptions);
                    }
                    */

                // showing all plans
                setSubscriptionPlansOptions(filteredPlansOptionsWithAmountSaved);
                setShowPlans(true);
            }
        },
        [plans, availableMonth]
    );

    const setOptionsImages = useCallback(() => {
        if (relationship === CONSTANTS.GRANDPARENTS) {
            recreateOptions({
                gender: "grandparents.svg",
                type: "couple",
            });
        } else {
            if (relationship === CONSTANTS.GRANDMA) {
                recreateOptions({
                    gender: "women.svg",
                    type: "single",
                });
            } else {
                recreateOptions({
                    gender: "men.svg",
                    type: "single",
                });
            }
        }
    }, [recreateOptions, relationship]);

    useEffect(() => {
        setOptionsImages();
        setSubscription(subscriptions.subscriptionPlan);
    }, [setOptionsImages, subscriptions.subscriptionPlan, plans]);

    const onSelectPlan = (selection) => {
        if (subscription.length > 0) {
            subscription.pop();
        }

        setSubscription((old) => [
            ...old,
            {
                ...selection,
                autoRenewing: true,
            },
        ]);
    };

    const saveStepFourAndNext = () => {
        if (subscription !== "") {
            saveDiscountCoupon(null);

            saveStepFour({
                subscriptionPlan: subscription,
                oneTimeGift: false,
            });

            saveStepFive({ upgradeBoxes: [] });

            if (isJoiningUser) {
                const prices = upgrades.flatMap((upgrade, i) => upgrade.prices);

                let boxSize = null;

                if (relationship === CONSTANTS.GRANDPARENTS) {
                    boxSize = "couple";
                } else {
                    boxSize = "single";
                }

                const selectedUpgrade = prices.find(
                    (item) =>
                        item.metadata.upgradeType === otGiftType && item.metadata[boxSize] && item.metadata.upgrade
                );

                saveStepFive({
                    upgradeBoxes: [
                        {
                            ...selectedUpgrade,
                            autoRenewing: true,
                        },
                    ],
                });

                history.push(ROUTES.STEP_SIX);
            } else {
                history.push(ROUTES.STEP_FIVE);
            }
        }
    };

    let stepFourComponent = null;

    if (showPlans) {
        stepFourComponent = (
            <StepFourComponent
                subscriptionPlansOptions={subscriptionPlansOptions}
                subscription={subscription}
                history={history}
                subscriptions={subscriptions}
                saveStepFourAndNext={saveStepFourAndNext}
                onSelectPlan={onSelectPlan}
            />
        );
    } else {
        stepFourComponent = <Loader />;
    }

    return (
        <>
            <Container>{stepFourComponent}</Container>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    saveDiscountCoupon: (couponData) => dispatch(saveDiscountCoupon(couponData)),
    saveStepFour: (stepFour) => dispatch(saveStepFour(stepFour)),
    saveStepFive: (stepFive) => dispatch(saveStepFive(stepFive)),
});

const mapStateToProps = (state) => {
    return {
        subscriptions: state.subscriptions,
        plans: state.business.plans,
        upgrades: state.business.upgrades,
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(withRouter(StepFour));
